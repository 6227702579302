import React from 'react';

class SignupPage extends React.Component {
  componentDidMount() {
    window.history.replaceState({}, "", "/")
    window.location.href = "http://masetech.1keto.hop.clickbank.net/?pid=sku2"
  }

  render() {
    return <div style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: '#212d3b' }}></div>
  }
}

export default SignupPage;